@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato-Regular'),
    url('/fonts/lato/Lato-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: bold;
  font-weight: 700;
  src: local('Lato-Bold'),
    url('/fonts/lato/Lato-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Lato-Bold-Italic';
  src: local('Lato-Bold-Italic'),
    url('/fonts/lato/Lato-Bold-Italic.woff2') format('woff2');
}

/* LEGACY ------------------- */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat-Regular'),
    url('/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: bold;
  font-weight: 700;
  src: local('Montserrat-Bold'),
    url('/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}
/* ------------------- */