.maplibregl-ctrl-attrib,
.maplibregl-ctrl-logo,
.maplibregl-popup-close-button,
.maplibregl-popup-tip {
  display: none;
  background-color: hsl(var(--neutral-0));
}

.maplibregl-popup-content {
  background: transparent;
  padding: 0;
}

.maplibregl-popup {
  z-index: 2;
}

.maplibregl-ctrl-group {
  background-color: hsl(var(--neutral-900));

  button.maplibregl-ctrl-zoom-in {
    border: 1px solid hsl(var(--neutral-700));
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;

    .maplibregl-ctrl-icon {
      border-color: hsl(var(--neutral-700));

      :hover {
        background-color: hsl(var(--neutral-800));
      }

      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
  }

  button.maplibregl-ctrl-zoom-out {
    border: 1px solid hsl(var(--neutral-700));
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: none;

    .maplibregl-ctrl-icon {
      border-color: hsl(var(--neutral-700));

      :hover {
        background-color: hsl(var(--neutral-800));
      }

      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
    }
  }
}

.maplibregl-ctrl-bottom-right {
  right: 16px;
  bottom: 16px;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
}

.maplibregl-ctrl-bottom-right .maplibregl-ctrl {
  margin: 0;
}

.maplibregl-ctrl-group button {
  width: 44px;
  height: 44px;
  border-top: none;
  border: 1px solid hsl(var(--neutral-0));
}

[data-type='light'] .maplibregl-ctrl-group {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 1.5;
  background-color: hsl(var(--neutral-0));

  button.maplibregl-ctrl-zoom-in {
    .maplibregl-ctrl-icon {
      :hover {
        background-color: hsl(var(--neutral-0));
      }

      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%230A0E14'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
    }
  }

  button.maplibregl-ctrl-zoom-out {
    .maplibregl-ctrl-icon {
      :hover {
        background-color: hsl(var(--neutral-0));
      }

      border-top: 1px solid hsl(var(--neutral-200));
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%230A0E14'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
    }
  }
}

.mapdraw {
  .maplibregl-ctrl-bottom-left {
    margin-left: 2;
    margin-bottom: 6;
  }

  .maplibregl-ctrl-group button {
    width: 44;
    height: 44;
  }

  .maplibregl-ctrl-attrib,
  .maplibregl-ctrl-logo,
  .maplibregl-popup-close-button,
  .maplibregl-popup-tip {
    display: 'none';
    background-color: hsl(var(--neutral-0));
  }

  .maplibregl-popup-content {
    padding: 0.2;
    width: 324;
    height: 179;
    font-weight: bold;
    background-color: hsl(var(--neutral-1000));
  }

  .mapboxgl-ctrl-zoom-in {
    border-top-left-radius: 14%;
    border-top-right-radius: 14%;
  }

  .mapboxgl-ctrl-zoom-out {
    border-bottom-left-radius: 14%;
    border-bottom-right-radius: 14%;
  }

  .maplibregl-ctrl-bottom-right {
    right: 24px;
    bottom: 10px;
    border: 1px solid hsl(var(--neutral-700));
    border-radius: 10%;

    @media (min-width: 1024px) {
      bottom: 40px;
    }

    @media (min-width: 0px) {
      display: flex;
    }
  }

  .maplibregl-ctrl-group button {
    border-top: none;
  }

  .maplibregl-ctrl-group .maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
  }

  .maplibregl-ctrl-group .maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
    border-top: 1px solid var(--neutral-800);
  }

  .maplibregl-ctrl-bottom-right .mapbox-gl-draw_polygon {
    position: absolute;
    height: 44px;
    bottom: 100px;
    border-radius: 14%;
    border: 1px solid hsl(var(--neutral-700));
    border-radius: 10%;
    pointer-events: auto;
    background-color: hsl(var(--neutral-900));
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='28' height='28' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M23.8226 8.74686L16.6281 3.62545V0H11.3781V3.62545L4.18369 8.74686H0V14H1.83154L4.72939 22.75H4.375V28H9.62814V26.25H18.3907V28H23.625V22.75H23.2769L26.1747 14H28V8.74686H23.8226ZM13.1281 1.75H14.8781V3.5H13.1281V1.75ZM3.5 12.2531H1.75V10.5031H3.5V12.2531ZM7.89068 26.25H6.13127V24.5H7.87814L7.89068 26.25ZM21.8875 26.25H20.1281V24.5H21.875L21.8875 26.25ZM21.4328 22.75H18.3907V24.5H9.62814V22.75H6.57348L3.67563 14H5.24373V10.1425L12.1183 5.25H15.8974L22.75 10.1362V14H24.3181L21.4328 22.75ZM26.25 12.2531H24.5V10.5031H26.25V12.2531Z' fill='%23ffffff'/%3E%3C/svg%3E");

    &.active {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='28' height='28' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg' fill='%23ffffff'%3E%3Cpath d='M23.8226 8.74686L16.6281 3.62545V0H11.3781V3.62545L4.18369 8.74686H0V14H1.83154L4.72939 22.75H4.375V28H9.62814V26.25H18.3907V28H23.625V22.75H23.2769L26.1747 14H28V8.74686H23.8226ZM13.1281 1.75H14.8781V3.5H13.1281V1.75ZM3.5 12.2531H1.75V10.5031H3.5V12.2531ZM7.89068 26.25H6.13127V24.5H7.87814L7.89068 26.25ZM21.8875 26.25H20.1281V24.5H21.875L21.8875 26.25ZM21.4328 22.75H18.3907V24.5H9.62814V22.75H6.57348L3.67563 14H5.24373V10.1425L12.1183 5.25H15.8974L22.75 10.1362V14H24.3181L21.4328 22.75ZM26.25 12.2531H24.5V10.5031H26.25V12.2531Z' fill='%231E58AE'/%3E%3C/svg%3E");
      background-color: hsl(var(--neutral-900));
    }

    &:hover {
      background-color: hsl(var(--neutral-900));
    }
  }

  .maplibregl-ctrl-bottom-right .mapbox-gl-draw_polygon .hasClosedPolygon {
    pointer-events: none;
    background-color: hsl(var(--neutral-900));
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='28' height='28' viewBox='0 0 28 28' xmlns='http://www.w3.org/2000/svg' fill='%233F454D'%3E%3Cpath d='M23.8226 8.74686L16.6281 3.62545V0H11.3781V3.62545L4.18369 8.74686H0V14H1.83154L4.72939 22.75H4.375V28H9.62814V26.25H18.3907V28H23.625V22.75H23.2769L26.1747 14H28V8.74686H23.8226ZM13.1281 1.75H14.8781V3.5H13.1281V1.75ZM3.5 12.2531H1.75V10.5031H3.5V12.2531ZM7.89068 26.25H6.13127V24.5H7.87814L7.89068 26.25ZM21.8875 26.25H20.1281V24.5H21.875L21.8875 26.25ZM21.4328 22.75H18.3907V24.5H9.62814V22.75H6.57348L3.67563 14H5.24373V10.1425L12.1183 5.25H15.8974L22.75 10.1362V14H24.3181L21.4328 22.75ZM26.25 12.2531H24.5V10.5031H26.25V12.2531Z' fill='%23ffffff'/%3E%3C/svg%3E");

    &:hover {
      background-color: hsl(var(--neutral-900));
    }
  }
}

#scout-map {
  .maplibregl-ctrl-group button {
    border: none;
  }
}

div#almanac-mini-map {
  canvas.maplibregl-canvas {
    &:focus-visible {
      outline: none;
    }
  }
}
