@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .light {

    /* Shadcn */
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;

    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;

    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;

    --radius: 0.5rem;
  }

  .dark {

    /* Azure */
    --azure-400: 216 96% 78%;
    --azure-500: 216 94% 68%;
    --azure-600: 216 92% 60%;
    --azure-700: 216 84% 56%;
    --azure-800: 216 76% 48%;
    --azure-900: 216 71% 40%;

    /* Neutral */
    --neutral-0: 0 0% 100%;
    --neutral-100: 216 40% 98%;
    --neutral-200: 216 40% 96%;
    --neutral-300: 216 30% 91%;
    --neutral-400: 213 11% 67%;
    --neutral-500: 216 20% 65%;
    --neutral-600: 216 16% 45%;
    --neutral-700: 214 10% 27%;
    --neutral-800: 216 15% 16%;
    --neutral-900: 216 18% 13%;
    --neutral-1000: 216 33% 6%;

    /* Tropical Indigo */
    --tropicalIndigo-400: 249 95% 85%;
    --tropicalIndigo-500: 249 92% 76%;
    --tropicalIndigo-600: 249 93% 71%;
    --tropicalIndigo-700: 249 91% 65%;
    --tropicalIndigo-800: 249 72% 54%;
    --tropicalIndigo-900: 249 69% 42%;

    /* Electric Blue */
    --electricBlue-400: 188 93% 82%;
    --electricBlue-500: 186 91% 71%;
    --electricBlue-600: 188 84% 60%;
    --electricBlue-700: 186 75% 53%;
    --electricBlue-800: 186 67% 44%;
    --electricBlue-900: 186 70% 35%;

    /* Pumpkin */
    --pumpkin-400: 24 93% 82%;
    --pumpkin-500: 24 91% 71%;
    --pumpkin-600: 24 84% 60%;
    --pumpkin-700: 24 75% 53%;
    --pumpkin-800: 24 67% 44%;
    --pumpkin-900: 24 70% 35%;

    /* Cinnabar Danger */
    --cinnabarDanger-400: 9 93% 82%;
    --cinnabarDanger-500: 9 91% 71%;
    --cinnabarDanger-600: 9 84% 60%;
    --cinnabarDanger-700: 9 75% 53%;
    --cinnabarDanger-800: 9 67% 44%;
    --cinnabarDanger-900: 9 70% 35%;

    /* Veridian Success */
    --veridianSuccess-400: 168 72% 67%;
    --veridianSuccess-500: 168 64% 52%;
    --veridianSuccess-600: 168 84% 35%;
    --veridianSuccess-700: 168 89% 28%;
    --veridianSuccess-800: 168 94% 23%;
    --veridianSuccess-900: 168 88% 20%;

    /* Harvest Gold Warning */
    --harvestGoldWarning-300: 38 98% 83%;
    --harvestGoldWarning-400: 38 97% 72%;
    --harvestGoldWarning-500: 38 96% 61%;
    --harvestGoldWarning-600: 38 85% 47%;
    --harvestGoldWarning-700: 38 90% 37%;
    --harvestGoldWarning-800: 38 82% 32%;

    --pinkPulse-light: 306 65% 52%;
    --pinkPulse-main: 315 42% 51%;

    --scoutBoundary-light: 216 92% 60%;
    --scoutBoundary-main: 216 76% 48%;

    /* Shadcn */
    --background: 216 15% 16%;
    --foreground: 0 0% 100%;

    --card: 216 33% 6%;
    --card-foreground: 0 0% 100%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 0 0% 100%;

    --primary: 216 84% 56%;
    --primary-foreground: 0 0% 100%;

    --secondary: 216 15% 16%;
    --secondary-foreground: 0 0% 100%;

    --muted: 216 15% 16%;
    --muted-foreground: 216 16% 45%;

    --accent: 216 15% 16%;
    --accent-foreground: 0 0% 100%;

    --destructive: 9 75% 53%;
    --destructive-foreground: 0 0% 100%;

    --border: 214 10% 27%;
    --input: 214 10% 27%;
    --ring: 216 84% 56%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
    box-sizing: border-box;
  }

  html {
    @apply no-scrollbar;
    height: 100%;
  }

  #__next {
    height: 100%;
  }

  body {
    @apply bg-background text-foreground;
    font-family: 'Lato', sans-serif;
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

@layer components {
  .container {
    @apply max-w-full relative
  }
}

@layer utilities {
/* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .react-zoom-children > div {
    @apply h-full !important;
  }

  .react-zoom-children > div > div {
    @apply h-full !important;
  }

  .no-focus-ring {
    @apply focus-visible:outline-0 focus-visible:ring-transparent focus-visible:ring-offset-0 !important
  }
}