.react-autocomplete-input {
    position: fixed;
    z-index: 1000;
    margin-left: 10px;
}
  
.react-autocomplete-input > li {
    cursor: pointer;
    padding: 10px;
    min-width: 100px;
    background-color: hsl(var(--neutral-300));
    color: hsl(var(--neutral-900));
}
  
.react-autocomplete-input > li.active {
    background-color: hsl(var(--azure-400));
    color: hsl(var(--neutral-900));
}